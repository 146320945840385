import React, { Suspense } from "react";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { I18nextProvider } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PrimeReactProvider } from "primereact/api";
import dayjs from "dayjs";
import i18n from "./i18n";
import "./App.css";

let persistor = persistStore(store);

export default function Root(props) {
  return (
    <PrimeReactProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
        <BrowserRouter>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <Suspense fallback={null}>
                <Provider store={store}>
                  <App />
                </Provider>
              </Suspense>
            </I18nextProvider>
          </PersistGate>
        </BrowserRouter>
      </LocalizationProvider>
    </PrimeReactProvider>
  );
}
