import {
  Stack,
  MenuItem,
  MenuList,
  Popover,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ShortCuts = ({ open, anchorEl, handleCloseNotification }) => {
  const { t } = useTranslation(["request"]["general"]);

  const navigate = useNavigate();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleCloseNotification}
      sx={{
        mt: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          margin: "15px",
          minWidth: "150px",
        }}
      >
        <MenuList sx={{ width: 320, maxWidth: "100%" }}>
          <Typography variant="overline" display="block" gutterBottom>
            {t("general:Atajos")}
          </Typography>
          <MenuItem
            onClick={() => {
              navigate("/cereza-organization/requests");
            }}
          >
            <ListItemIcon>
              <ReduceCapacityIcon />
            </ListItemIcon>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                padding: "10px",
              }}
            >
              {t(`request:STAFFREQUESTS`)}
            </div>
          </MenuItem>
        </MenuList>
      </Stack>
    </Popover>
  );
};
